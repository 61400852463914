import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "korea",
  save: process.env.NODE_ENV === "production",
  languages: [
    new MLanguage("english").create({
      main_title: "english title",
    }),

    new MLanguage("korea").create({
      /*home*/
      footer:
        "[38099] 경주시 양정로 300 (경주시보건소 2층) | TEL : 054)777-1577 | FAX : 054)777-1571 | E-mail : gjmind@hanmail.net<br />Copyright ⓒ 2021 경주시정신건강복지센터. All Rights Reserved. Made by ㈜힐러스",
      footer_m:
        "[38099] 경주시 양정로 300 (경주시보건소 2층) | TEL : 054)777-1577<br />FAX : 054)777-1571 | E-mail : gjmind@hanmail.net<br />Copyright ⓒ 2021 경주시정신건강복지센터. All Rights Reserved. Made by ㈜힐러스",
      loading_text: "잠시만 기다려주세요.",
      toast_finish: "한번더 누르시면 종료됩니다.",
      main_title: "시민들의 한명 한명, 마음을 어루만져주는 마음 소통 창구",
      main_button_01: "서비스 소개",
      main_button_02: "자가 마음진단",
      main_button_03: "온라인 상담",
      main_button_04: "힐링콘텐츠",
      main_button_05: "사용후기",
      main_button_06: "후기쓰기",
      main_result_button: "자가 진단 하기",
      main_button_01_m: "자가 마음진단",
      main_button_03_m: "호흡 훈련",
      main_button_04_m: "운동",
      main_button_05_m: "내가 본 콘텐츠",
      main_button_06_m: "추천 콘텐츠",
      main_button_07_m: "이용혜택",
      main_button_09_m: "협업기관",

      side_menu_button_01: "홈",
      side_menu_button_02: "서비스 소개",
      side_menu_button_03: "자가 마음진단",
      side_menu_button_04: "온라인 상담",
      side_menu_button_05: "힐링콘텐츠",
      side_menu_button_06: "사용후기",
      side_menu_button_07: "로그아웃",

      /*info*/
      info_title: "정신건강 플랫폼 온나눔을 소개합니다.",
      info_content:
        "안녕하세요. 경주시정신건강복지센터가 운영하는 정신건강 플랫폼 온나눔입니다.<br />" +
        "온나눔은 경주시민들의 마음건강과 행복한 삶을 위해<br />" +
        "자가검진, 온라인상담, 힐링콘텐츠를 제공하는 서비스입니다.<br /><br />" +
        "＊ 자가검진은 설문을 통해 아동부터 노인에 이르기까지 다양한 종류의 정신건강 관련 진단을 할 수 있습니다.<br />" +
        "＊ 온라인상담은 정신건강 관련 고민과 어려움을 나눌 수 있는 온라인 상담 서비스입니다.<br />" +
        "＊ 힐링콘텐츠는 명상, 호흡훈련, 오디오북, 감사일기 등의 콘텐츠를 제공합니다.<br /><br />" +
        "정신건강 플랫폼 온나눔을 통해 경주 시민들의 정신건강증진에 보탬이 되겠습니다.<br />" +
        "감사합니다.",
      info_content_m:
        "안녕하세요. 경주시정신건강복지센터가 운영하는 정신건강 플랫폼 온나눔입니다.<br />" +
        "온나눔은 경주시민들의 마음건강과 행복한 삶을 위해 자가검진, 온라인상담, 힐링콘텐츠를 제공하는 서비스입니다.<br /><br />" +
        "＊ 자가검진은 설문을 통해 아동부터 노인에 이르기까지 다양한 종류의 정신건강 관련 진단을 할 수 있습니다.<br />" +
        "＊ 온라인상담은 정신건강 관련 고민과 어려움을 나눌 수 있는 온라인 상담 서비스입니다.<br />" +
        "＊ 힐링콘텐츠는 명상, 호흡훈련, 오디오북, 감사일기 등의 콘텐츠를 제공합니다.<br /><br />" +
        "정신건강 플랫폼 온나눔을 통해 경주 시민들의 정신건강증진에 보탬이 되겠습니다.<br />" +
        "감사합니다.",

      /*d_s*/
      d_s_title: "아래 목록에서 원하는 검사 항목을 선택하세요.",
      d_s_title_m: "원하는 검사를 선택해 주세요.",
      d_s_list_btn: "선택",

      d_s_info_btn: "시작하기",

      d_s_text_btn: "뒤로",
      d_s_text_insert_btn: "제출",
      d_s_text_not_phone: "양식에 맞는 연락처를 입력해주세요.",
      d_s_text_not_seleft: "모든 질문에 답을 하셔야 합니다.",

      /*result*/
      result_cs_confirm: "전화상담을 신청하시겠습니까?\n입력하신 휴대폰번호로 빠른 시일 내에 전화가 갈 예정입니다",
      result_cs_alert_1: "신청이 완료되었습니다",
      result_cs_alert_2: "신청이 취소되었습니다",
      result_cs_alert_3: "상담 신청이 실패했습니다. 다시 시도해주세요.",
      result_sub_menu_1: "온라인 상담",
      result_sub_menu_1_info: "깊이 있는 상담을 경험하세요.",
      result_sub_menu_2: "힐링콘텐츠 보기",
      result_sub_menu_2_info: "다양한 콘텐츠로 힐링합니다.",
      result_sub_menu_3: "전화상담",
      result_sub_menu_3_info: "전화상담 정보를 안내해 드립니다.",
      result_sub_menu_4: "홈으로",
      result_sub_menu_4_info: "홈 화면으로 돌아갑니다.",

      /*counsel*/
      counsel_start_title: "처음 오셨나요?",
      counsel_start_yes_btn: "네, 처음 입니다.",
      counsel_start_no_btn: "아니요, 상담을 신청한 적이 있습니다.",
      counsel_login_title: "상담 신청 시 등록하신 정보를 입력해주세요.",
      counsel_login_tel_label: "휴대 전화번호",
      counsel_login_tel_rules_1: "*숫자만 가능",
      counsel_login_tel_rules_2: "*11개 숫자 모두 입력하세요.",
      counsel_login_pw_label: "비밀번호",
      counsel_login_pw_rules_1: "*숫자만 입력해주세요.",
      counsel_login_pw_check_label: "한번 더 입력",
      counsel_pw_legit_check: "*11자리 숫자만 입력",
      counsel_birthday_check: "*6자리 모두 입력하세요.",
      counsel_error_info: "잘못 입력하신 항목이 있습니다.",

      counsel_login_pw_check_rules_1: "* 비밀번호가 동일하지 않습니다. 다시 입력해 주세요.",
      counsel_login_pw_rules_2: "*4자리 숫자만 입력해주세요.",
      counsel_login_find_pw: "비밀번호를 잊어버렸어요!",
      counsel_login_btn: "확인",
      counsel_login_alert_error_1: "이용한 이력이 없으시거나, 비밀번호가 틀립니다.<br />다시 확인해 주세요.",
      counsel_list_table_title_1: "번호",
      counsel_list_table_title_2: "내용",
      counsel_list_table_title_3: "상담 등록일",
      counsel_list_table_title_4: "상담 답변",
      counsel_list_table_title_5: "답변 등록일",
      counsel_list_btn: "다시 상담요청하기",
      counsel_list_success_title: "귀하의 상담 내용이 전송되었습니다.",
      // counsel_list_success_title_2: "24시간 이내 답신이 도착합니다.",
      counsel_list_success_title_2: "빠른 시일 내로 답변이 올라올 예정입니다.",
      counsel_list_success_title_3: "※공휴일 및 기관의 사정에 의해 답신이 다소 지연될 수 있습니다.",
      counsel_write_title: "고민 내용과 기간, 자세한 증상을 적어주세요.",
      counsel_write_input_ph:
        "고민 내용과 기간 그리고 자세한 증상을 적어주세요.\n상세하게 적으실 수록 더 정확한 상담이 가능합니다.",
      counsel_write_alert_title: "온라인 상담",
      counsel_write_alert_error_1: "상담 내용은 최소 5자 이상 입력해주세요.",
      counsel_write_type_1: "정신건강상담",
      counsel_write_type_2: "정신건강서비스",
      counsel_write_type_3: "기타",
      counsel_write_btn: "보내기",

      /*after*/
      // after_use_list_title: "전문 상담사에 상담을 받아보세요.",
      after_use_list_title: "온나눔을 칭찬해주세요!",
      after_use_list_new_title: "소중한 후기를 작성해주셔서 감사합니다. ",
      thanks_diary_title_info: "경주시민이라면 누구나 감사일기를 신청할 수 있습니다.",
      thanks_diary_title_info_sub_title: "<br /> 신청된 감사일기는 2주에 한 번 발송됩니다. (색상 랜덤)",
      thanks_diary_title_info_and_sub_title:
        "경주시민이라면 누구나 감사일기를 신청할 수 있습니다. <br /> 신청된 감사일기는 2주에 한 번 발송됩니다. (색상 랜덤)",
      after_use_list_btn: "후기쓰기",
      after_use_list_send_btn: "신청하기",
      after_use_list_back_btn: "목록",
      after_use_list_detail_title_1: "이름",
      after_use_list_detail_title_2: "제목",
      after_use_list_detail_title_3: "내용",
      after_use_write_btn_2: "등록",
      after_use_write_img_btn: "이미지 첨부 하기",
      after_use_write_img_complete: "이미지 첨부 완료",
      after_use_write_name_ph: "이름을 입력해 주세요.",
      after_use_write_title_ph: "제목을 입력해 주세요.",
      after_use_write_content_ph: "내용을 입력해 주세요.",
      after_use_write_alert_title: "사용후기 쓰기",
      after_use_write_m_title: "사용 후기",
      after_use_write_m_title2: "후기 쓰기",
      after_use_write_alert_error_1: "*제목을 입력해 주세요.",
      after_use_write_alert_error_2: "*내용을 입력해 주세요.",
      after_use_write_alert_error_3: "*이름을 입력해 주세요.",
      after_use_write_alert_error_5: "*성별을 선택해 주세요.",
      after_use_write_alert_error_6: "*생년월일을 입력해 주세요.",
      after_use_write_alert_error_7: "*한글만 입력해 주세요.",
      after_use_write_alert_error_4: "첨부 사이즈는 20MB 이하만 가능합니다.",
      after_use_write_alert_success_title: "귀하의 사용후기가 등록되었습니다.",
      after_use_write_alert_success_info: "소중한 리뷰 감사합니다.",

      /*find*/
      find_pw_root: "비밀번호 재 설정",
      find_pw_title_1: "휴대전화 인증을 통해 본인 확인을 해주세요.",
      find_pw_title_2: "새로운 비밀번호를 입력해주세요.",
      find_pw_btn_1: "인증번호 받기",
      find_pw_btn_2: "확인",
      find_pw_alert_send_info:
        "문자 메시지로<br />인증번호를 발송하였습니다.<br /><br />휴대전화에서 확인하시고,<br />인증번호 4자리를 입력해주세요.",
      find_pw_timer_text: "초 안에 입력해주세요.",
      find_pw_check_lable: "인증번호 입력",
      find_pw_check_error_1: "인증번호가 맞지 않습니다.<br />다시 입력하시거나 인증번호를 재 발송하세요.",
      find_pw_change_info: "*4자리 숫자만 가능합니다.",
      find_pw_alert_change_success: "비밀번호가 변경되었습니다.<br />새 비밀번호로 로그인해 주세요.",
      find_pw_change_btn: "확인",

      /*contents*/
      contents_close_btn: "닫기",

      /* thanks diary */
      apply_thx_diary_btn: "감사일기 신청",
      apply_thx_diary_title: "감사일기 신청",
      apply_thx_diary_name_label: "이름",
      apply_thx_diary_sex_label: "성별",
      apply_thx_diary_birthday_label: "생년월일6자리",
      apply_thx_diary_addr_label: "받으실 주소",
      apply_thx_diary_check_label: "개인정보수집 이용동의",
      apply_thx_diary_check_label_click: "[ 내용 보기 ]",
      apply_thx_diary_check_error_1: "개인정보수집 이용동의를 체크해주세요.",
      apply_thx_diary_sex_error_1: "성별을 선택해주세요.",
      apply_thx_diary_sex_item_1: "남성",
      apply_thx_diary_sex_item_2: "여성",
      apply_thx_diary_alert_info: "“감사일기”는 경주시에 거주하는 시민분들만 신청 가능합니다.",
      /* thanks diary alert */
      apply_thx_diary_alert_title: "주소검색",

      after_send_alert_title: "신청완료",
      after_send_alert_fail_title: "신청실패",
      after_send_alert_fail_name: "이름을 입력해주세요.",

      after_send_alert_fail_birth: "생년월일을 입력해주세요.",
      after_send_alert_fail_birth_length: "생년월일 6자리를 입력해주세요.",
      after_send_alert_fail_phone: "전화번호는 숫자만 입력해주세요.",
      after_send_alert_fail_address: "주소를 입력해주세요.",
      after_send_btn_click:
        "감사일기 신청이 완료되었습니다.<br />감사일기를 받으신 후에는<br /> 사용후기를 꼭 써주시기 바랍니다. <br/></br/> 감사합니다 <br/>",

      order_kit_addr_alert_input_1: "우편번호",
      order_kit_addr_alert_input_2: "주소",
      order_kit_addr_alert_input_3: "상세주소",

      thanksdiary_addr_etc_rules_1: "상세주소를 입력해주세요.",
      thanksdiary_addr_rules: "*주소를 입력해주세요.",

      /*common*/
      go_home_btn: "홈으로",
      alert_ok_btn: "확인",
    }),
  ],
});
