<template>
  <!-- <v-app id="inspire" style="overflow-x: hidden; height: 100vh;"> -->
  <div id="app">
    <!-- <v-app style="position:fixed; width:100%; height: 100%; overflow-x: hidden; "> -->
    <v-app :class="$mq">
      <!-- 웹 헤더 -->
      <v-app-bar
        class="hidden-sm-and-down header_color header_z_index"
        :class="isRoot ? '' : 'color_black'"
        app
        flat
        clipped-right
        height="80"
      >
        <v-card flat tile width="100%" color="transparent">
          <v-row no-gutters class="align-center">
            <v-btn
              v-if="!isRoot"
              color="transparent"
              elevation="0"
              width="130"
              height="80"
              style="margin-right: 30px;"
              v-on:click="$router.push('/')"
            >
              <img
                v-if="isRoot"
                width="70"
                aspect-ratio="1"
                src="@/assets/ci_x.png"
              />
              <img
                v-else
                width="70"
                aspect-ratio="1"
                src="@/assets/ci_x_sub.png"
              />
            </v-btn>

            <v-spacer></v-spacer>
            <img width="210" aspect-ratio="1" />
            <v-spacer></v-spacer>
            <div aspect-ratio="1" style="width: 130px; text-align: right;">
              <v-btn
                color="transparent"
                elevation="0"
                width="91"
                height="80"
                v-on:click="sideMenuToggle()"
              >
                <img
                  v-if="isRoot && !$store.state.open_side_menu"
                  width="80"
                  src="@/assets/menu_x.png"
                />
                <img v-else width="80" src="@/assets/meun_on_x.png" />
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </v-app-bar>

      <!-- 모바일 헤더 -->
      <!-- <ion-header
        class="hidden-md-and-up header_color header_z_index mobile_header_position"
        :class="isRoot ? '' : 'color_black'"
      > -->
      <!-- <ion-toolbar ref="mobile_header"> -->
      <v-app-bar
        class="hidden-md-and-up header_color header_z_index"
        :class="isRoot ? '' : 'color_black'"
        app
        flat
        clipped-right
        height="60"
      >
        <v-app-bar app flat clipped-right color="transparent" height="60">
          <v-card
            flat
            tile
            class="mx-auto"
            width="1200"
            min-width="0"
            max-width="1200"
            color="transparent"
          >
            <v-row no-gutters class="align-center">
              <v-btn
                v-if="!isRoot"
                color="transparent"
                elevation="0"
                width="70"
                height="44"
                v-on:click="$router.push('/')"
              >
                <img
                  v-if="isRoot"
                  width="60"
                  aspect-ratio="1"
                  src="@/assets/ci_x.png"
                />
                <img
                  v-else
                  width="60"
                  aspect-ratio="1"
                  src="@/assets/ci_x_sub.png"
                />
              </v-btn>
              <v-spacer></v-spacer>
              <img width="125" aspect-ratio="1" />
              <v-spacer></v-spacer>
              <div aspect-ratio="1" style="width: 50px; text-align: right;">
                <v-btn
                  color="transparent"
                  elevation="0"
                  height="44"
                  width="50"
                  min-width="50"
                  v-on:click="sideMenuToggle()"
                >
                  <img
                    v-if="isRoot && !$store.state.open_side_menu"
                    width="30"
                    src="@/assets/menu_m.png"
                  />
                  <img v-else width="30" src="@/assets/menu_m_w.png" />
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-app-bar>
      </v-app-bar>
      <!-- </ion-toolbar> -->
      <!-- </ion-header> -->

      <v-main class="pa-0 ma-0">
        <!-- main -->
        <v-container class="fill-height pa-0" fluid>
          <router-view></router-view>
          <!-- <ion-vue-router /> -->
        </v-container>
        <!-- side menu -->
        <div
          class="side_menu"
          :class="$mq"
          v-show="$store.state.open_side_menu"
        >
          <v-row class="content" align="center" justify="center">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/')"
              >
                <!-- <v-row
                  class="fullheight justify-center align-center"
                  v-html="$ml.get('side_menu_button_01')"
                >
                </v-row> -->
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_01')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/info')"
              >
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_02')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/d_start')"
              >
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_03')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/counsel_start')"
              >
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_04')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/contents')"
              >
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_05')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu fullheight justify-center align-center"
                :class="{ 'on-hover': hover }"
                v-on:click="$router.push('/after_use_list')"
              >
                <div
                  class="side_menu_position"
                  v-html="$ml.get('side_menu_button_06')"
                ></div>
              </v-card>
            </v-hover>
            <v-hover v-if="$store.getters.getId != ''" v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                class="menu"
                :class="{ 'on-hover': hover }"
                v-on:click="logout()"
              >
                <v-row
                  class="fullheight justify-center align-center"
                  v-html="$ml.get('side_menu_button_07')"
                >
                </v-row>
              </v-card>
            </v-hover>
          </v-row>
        </div>

        <!-- alert popup -->
        <v-dialog
          :content-class="'popup ' + $mq"
          v-model="$store.state.alert"
          max-width="800"
          :persistent="$store.state.alert_persistent"
        >
          <v-card class="pa-5 popup_root">
            <v-card-title class="alert_title">
              <v-spacer />{{ $store.state.alert_title }}<v-spacer />
            </v-card-title>

            <v-card-text
              class="pa-0 my-10 d-flex align-center justify-center alert_title_sub_info"
              v-html="$store.state.alert_info"
            >
            </v-card-text>

            <v-btn
              class="mt-5 rounded-xl alert_btn"
              v-on:click="$store.state.alert_function"
            >
              {{ $ml.get("alert_ok_btn") }}
            </v-btn>
          </v-card>
        </v-dialog>

        <!-- toast -->
        <div class="toast" v-if="$store.state.open_toast">
          {{ $ml.get("toast_finish") }}
        </div>
      </v-main>

      <v-card class="error_popup" v-if="isError500">
        <div class="error_popup_root">
          <div class="error_popup_row">
            <div class="error_popup_col">
              <img
                class="error_popup_img_ie error_popup_col"
                src="@/assets/ci_x.png"
              />
            </div>
          </div>
          <div class="error_popup_row">
            <div class="error_popup_col">
              <img
                class="error_popup_img_ci error_popup_col"
                src="@/assets/ci_x.png"
              />
            </div>
          </div>
          <div class="error_popup_row">
            <div class="error_popup_col">
              <span class="error_popup_bold">네트워크 에러</span>
            </div>
          </div>
          <div class="error_popup_row min">
            사용하고 계신 단말기의 네트워크 연결을 확인해 주세요.<br /><br />
            연결에 문제가 없다면, 현재 시스템 점검 중 일 수 있습니다.<br />
            (시스템 점검은 최대 1시간 정도 소요됩니다.)
          </div>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { Plugins, StatusBarStyle, Capacitor } from "@capacitor/core";
const { App, StatusBar, Device, IntentPlugin } = Plugins;

export default {
  created: async function() {
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      window.location.href = "/explorer/notice.html";
    }

    document.documentElement.addEventListener(
      "touchstart",
      function(event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );

    this.deviceInfo = await Device.getInfo();
    this.$store.commit("platform_update", this.deviceInfo.platform);
  },
  mounted: async function() {
    //create의 async가 끝나기전에 호출되는 문제가 있어서
    //여기서도 직접 한번더 호출하여 사용한다.
    this.deviceInfo = await Device.getInfo();

    if (this.deviceInfo.platform !== "web") {
      StatusBar.setStyle({
        style: this.isStatusBarLight
          ? StatusBarStyle.Dark
          : StatusBarStyle.Light
      });

      this.$refs.mobile_header.style.marginTop = "60px";

      if (this.deviceInfo.platform === "android") {
        StatusBar.setBackgroundColor({
          color: "#FFFFFF"
        });
        this.$refs.mobile_header.style.marginTop = "0px";
      }

      StatusBar.show();

      // global events
      App.addListener("appStateChange", state => {
        // state.isActive contains the active state
        console.log("App state changed. Is active?", state.isActive);
      });
    }

    var self = this;
    if (Capacitor.isNative) {
      const intentPluginEventListener = Plugins.IntentPlugin.addListener(
        "intentPluginEvent",
        function(info) {
          // info => {
          if (info != null) {
            intentPluginEventListener.remove();
            self.$store.state.push_token = info.token;
            self.$store.state.hash_key = info.hash.replaceAll("\n", "");
          }

          ///////////////////////////////////////////////////
          //사용자 정보 가져오기
          // self.$store.dispatch("loginGubun", {}).then(() => {
          //   if (self.$store.state.api_result == "err") {
          //     self.isError500 = true;
          //   }
          // });
        }
      );
    } else {
      ///////////////////////////////////////////////////
      //사용자 정보 가져오기
      // self.$store.dispatch("loginGubun", {});
    }

    // permission dialog call
    this.callShowPermission();

    this.callPushToken();
  },
  data: () => ({
    isStatusBarLight: false,
    deviceInfo: {},
    isError500: false
  }),
  methods: {
    sideMenuToggle: function() {
      this.$store.state.open_side_menu = !this.$store.state.open_side_menu;
    },
    logout: function() {
      var self = this;

      self.$store.dispatch("logout").then(() => {
        self.$store.state.open_side_menu = false;
        self.$router.push("/");
      });
    },
    callPushToken: function() {
      if (Capacitor.isNative) {
        // Native
        IntentPlugin.callPushToken();
      }
    },
    callShowPermission: function() {
      if (Capacitor.isNative) {
        // Native
        // if(this.$store.state.platfrom == 'ios')
        // {
        IntentPlugin.callShowPermission();
        // }
      }
    }
  },
  computed: {
    isRoot() {
      return this.$route.fullPath == "/";
    }
  },
  watch: {}
};

/*
// 접속 핸드폰 정보
var userAgent = navigator.userAgent.toLowerCase();
// 모바일 홈페이지 바로가기 링크 생성
if (userAgent.match("iphone")) {
  document.write('<link rel="apple-touch-icon" href="/img/icon_72.png" />');
} else if (userAgent.match("ipad")) {
  document.write(
    '<link rel="apple-touch-icon" sizes="72*72" href="/img/icon_144.png" />'
  );
} else if (userAgent.match("ipod")) {
  document.write('<link rel="apple-touch-icon" href="/img/icon_72.png" />');
} else if (userAgent.match("android")) {
  document.write('<link rel="shortcut icon" href="/img/favicon.ico" />');
} else {
  document.write('<link rel="shortcut icon" href="/img/favicon.ico" />');
}
*/
</script>

<style lang="scss">
@import "src/assets/css/common";
/* basic style */
#app {
  font-family: Noto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

//test123
// .v-application--wrap {
//   position: fixed !important;
//   width: 100%;
//   height: 100%;
//   overflow: hidden scroll;
// }
body {
  position: relative !important;
  height: auto !important;
  max-height: none !important;
}

////////////

@font-face {
  font-family: "Noto";
  src: url("./assets/font/GmarketSansTTFMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.fullwidth {
  width: 100% !important;
  padding: 0px !important;
}
.fullheight {
  min-height: 100% !important;
  padding: 0px !important;
}
////////////////////////////////////

/* popup css */
.popup {
  margin-top: 100px !important;
  max-height: calc(90% - 100px) !important;
  border-radius: 20px !important;
}

.popup_root {
  height: auto;
  min-height: 100%;
}

.popup.thumb_popup {
  overflow: hidden;
  height: calc(90% - 100px);
}

.popup.thumb_popup.type_5 {
  overflow: hidden;
  height: auto;
}

.dialog_full {
  margin: 0px !important;
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100% !important;
  overflow: hidden scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dialog_full::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.dialog_full.type_5 {
  overflow: auto !important;
  max-width: 200vh;
  background: #fff;
}
////////////////////////////////////

/* alert css */
.alert_title {
  font: normal normal bold 28px/36px Noto !important;
  letter-spacing: 0px !important;
  color: $ft-color-alert-title !important;
  padding: 59px 0px 0px 0px !important;
}
.alert_title_sub_info {
  font: normal normal normal 28px/38px Noto !important;
  letter-spacing: 0px !important;
  color: #707070 !important;
  text-align: center;
  padding: 0px !important;
}

.alert_btn {
  height: 80px !important;
  width: 500px !important;
  background-color: $bg-color-btn-1 !important;
  color: $ft-color-btn-1 !important;
  font: normal normal bold 30px/36px Noto !important;
}

.sm {
  .alert_title {
    font: normal normal normal 5vw/6vw Noto !important;
  }
  .alert_title_sub_info {
    min-height: 10vw;
    font: normal normal normal 5vw/6vw Noto !important;
  }
  .alert_btn {
    width: 100% !important;
    height: 16vw !important;
    font: normal normal bold 5vw/6vw Noto !important;
  }
}
////////////////////////////////////

/*error popup css*/
.error_popup {
  position: absolute !important;
  z-index: 300;
  height: 100%;
  width: 100%;
  color: #444444 !important;
  font-size: 3vw;
  line-height: 15vw;
  text-align: center;
}

.error_popup_bold {
  color: #444444;
  font-size: 6vw;
  font-weight: bold;
}

.error_popup_root {
  width: auto;
  margin: 20vh auto;
}

.error_popup_row {
  height: 17vw;
}

.error_popup_row.min {
  line-height: 7vw;
}

.error_popup_img_ie {
  height: 9vw;
}

.error_popup_img_ci {
  height: 10vw;
}
////////////////////////////////////

/* main css */
.header_color {
  background-color: $bg-color-header !important;
}

.header_color.color_black {
  background-color: $bg-color-header-black !important;
}

.header_z_index {
  z-index: 200 !important;
}

.mobile_header_position {
  position: absolute;
}
////////////////////////////////////

/* side menu css */
.side_menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background: $bg-color-side-menu;
  z-index: 199;

  .content {
    position: relative;
    width: 282px;
    left: calc(50vw - 141px);
    top: 18%;

    .menu {
      cursor: pointer;
      height: 0px;
      width: 100%;
      font-family: Noto;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: $ft-color-side-menu;
      margin-bottom: 90px;
      padding: 0px !important;
      box-shadow: none !important;
    }
    .menu.on-hover {
      color: $ft-color-side-menu-hover !important;
    }
    .menu:before {
      opacity: 0;
    }
  }
}

.sm.side_menu {
  .content {
    width: auto;
    left: 0px;

    .menu {
      // font-size: 24px;
      font-size: 6vw;
      // margin-bottom: 60px;
      margin-bottom: 14vw;
    }
  }
}

////////////////////////////////////

/*common css*/
.bg_card {
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  padding-top: 100px !important;
}

.title_root {
  margin: 0px !important;
  padding: 20px 0px 0px 0px;
}

.title_root_css {
  width: 100%;
  max-width: 1040px !important;
  border-radius: 20px !important;
}

.new_title_root_css {
  width: 100%;
  max-width: 1040px !important;
  margin-bottom: 50px;
  border-radius: 20px !important;
}

.title_menu {
  margin-top: 32px;
  margin-bottom: 32px;
}

.title_root_text {
  margin-top: 12px;
  height: 60px;
  font: normal normal bold 28px/36px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
}

.title_root_text.font_white {
  color: $ft-color-title-white;
}

.title_root_text_line {
  border-top: solid 6px $ft-color-title;
  height: 10px;
  width: 40px;
}

.title_root_text_line.font_white {
  border-top: solid 6px $ft-color-title-white;
}

.title_root_back {
  width: 44px !important;
  height: 44px !important;
  margin: 8px;
  position: absolute !important;
  left: -12px;
  top: 38px;
  background-image: url("./assets/back_button_x.png");
  background-size: contain;
}

.main_root {
  margin: 0px !important;
  padding: 50px 0px;
}

.main_root_back {
  display: none !important;
}

.main_title_1 {
  height: 80px;
  background-color: $bg-color-main-title;
  margin: 0px !important;
}

.main_title_1_text {
  align-self: center;
  font: normal normal normal 24px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
}

.info_text_0 {
  word-break: keep-all;
  align-self: center;
  font: normal normal normal 24px/30px Noto;
  letter-spacing: 0px;
  color: $ft-color-title;
  padding: 40px;
}

.info_text_1 {
  word-break: keep-all;
  font: normal normal normal 20px/26px Noto;
  letter-spacing: 0px;
  color: $ft-color-d_start-title-sub-1;
  padding: 10px;
}

.button_1 {
  height: 80px !important;
  width: 100%;
  background-color: $bg-color-btn-1 !important;
  color: $ft-color-btn-1 !important;
  font: normal normal bold 30px/36px Noto !important;
}

.button_2 {
  height: 80px !important;
  width: 100%;
  background-color: $bg-color-btn-2 !important;
  color: $ft-color-btn-2 !important;
  font: normal normal bold 30px/36px Noto !important;
}

.button_3 {
  height: 80px !important;
  width: 100%;
  background-color: $bg-color-btn-3 !important;
  color: $ft-color-btn-3 !important;
  font: normal normal bold 30px/36px Noto !important;
}

.button_text {
  font: normal normal bold 26px/34px Noto;
  letter-spacing: 0px;
  color: $ft-color-btn-1;
}

.button_text2 {
  font: normal normal bold 26px/34px Noto;
  letter-spacing: 0px;
  color: $ft-color-btn-1 !important;
  background: $bg-color-btn-1 0% 0% no-repeat padding-box !important;
}

.button_text2:hover {
  cursor: pointer;
}

/* common css - mobile */
.sm.bg_card {
  background-attachment: initial;
  padding-top: 60px !important;
  padding-bottom: 100px !important;

  .title_root {
    display: none;
  }

  .main_root {
    padding: 4vw;
    border-radius: 24px;
  }

  .main_title_1 {
    height: 16vw !important;
    border: none !important;
  }

  .main_title_1_text {
    font: normal normal bold 5vw/6vw Noto;
  }

  .main_title_1.sub {
    background-color: $bg-color-main-title-sub;
    .main_title_1_text {
      font: normal normal bold 4vw/5vw Noto;
    }
  }

  .main_root_back {
    display: block !important;
    position: absolute !important;
    width: 8vw !important;
    height: 8vw !important;
    margin: 4vw;
    left: 0px;
    top: 0vw;
    background-image: url("./assets/back_button_o.png");
    background-size: contain;
  }

  .info_text_0 {
    font: normal normal normal 4vw/6vw Noto;
    padding: 10vw;
  }

  .info_text_1 {
    font: normal normal normal 4vw/5vw Noto;
    padding: 3vw;
  }

  .button_1 {
    height: 16vw !important;
    font: normal normal bold 5vw/6vw Noto !important;
  }

  .button_2 {
    height: 16vw !important;
    background-color: $bg-color-btn-2-m !important;
    font: normal normal bold 5vw/6vw Noto !important;
  }

  .button_3 {
    height: 16vw !important;
    color: $ft-color-btn-3-m !important;
    background-color: $bg-color-btn-3-m !important;
    font: normal normal bold 5vw/6vw Noto !important;
  }

  .button_text {
    // font: normal normal bold 18px Noto !important;
    font: normal normal bold 4vw/5vw Noto;
  }
}
////////////////////////////////////

/*toast css*/
.toast {
  position: fixed;
  bottom: 5%;
  height: 50px;
  line-height: 50px;
  width: 90%;
  left: 5%;
  z-index: 301;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
  text-align: center;
  color: white;
  font-size: 17px;
}
////////////////////////////////////

/* vuetify custom */
.h-checkbox .v-label {
  font-family: Noto !important;
  font-size: 18px !important;
  line-height: 26px;
}
.h-checkbox.sm .v-label {
  font-family: Noto !important;
  font-size: 13px !important;
  line-height: 14px;
}

.h-dialog-shape {
  min-width: 0px;
  min-height: 0px;
  margin: auto;
  padding: auto;
}
.h-dialog-shape.sm {
  transform-origin: center center;
  border-radius: 0;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.v-messages {
  font-size: 16px !important;
  .v-messages__message {
    line-height: initial !important;
  }
}

.sm {
  .v-messages {
    font-size: 2.8vw !important;
  }

  .v-input input {
    padding: 0px !important;
    max-height: 6vw !important;
  }

  .v-input input {
    padding: 0px !important;
    max-height: 6vw !important;
  }

  .v-textarea textarea {
    line-height: 7vw !important;
  }

  .v-select.v-input--dense .v-select__selection--comma {
    line-height: 5vw !important;
  }

  .v-list-item .v-list-item__title {
    font: normal normal normal 4.5vw/5vw Noto !important;
  }

  .v-input--selection-controls__input {
    transform: scale(1.3);
  }

  .v-label {
    font: normal normal normal 3.2vw/5vw Noto !important;
  }
}

////////////////////////////////////

/* animation */
.animation_top {
  opacity: 0;
}
.animation_bottom {
  opacity: 0;
}
.animation_show {
  opacity: 0;
}

.animation_top.start {
  opacity: 1;

  -moz-animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;

  -moz-animation-name: animation_top;
  -webkit-animation-name: animation_top;
  animation-name: animation_top;

  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  -moz-animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
@keyframes animation_top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animation_bottom.start {
  opacity: 1;

  -moz-animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;

  -moz-animation-name: animation_bottom;
  -webkit-animation-name: animation_bottom;
  animation-name: animation_bottom;

  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  -moz-animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
@keyframes animation_bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animation_show.start {
  opacity: 1;

  -moz-animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;

  -moz-animation-name: animation_show;
  -webkit-animation-name: animation_show;
  animation-name: animation_show;

  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  -moz-animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
@keyframes animation_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* header & footer */
.ion-no-border {
  --border-width: 0 !important;
}
.font-underline {
  text-decoration: underline !important;
}
// safe-area-inset for capacitor device
ion-navbar.toolbar.toolbar-ios.statusbar-padding,
ion-navbar.toolbar-ios ion-title.title-ios,
ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
ion-toolbar.toolbar-ios ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

ion-toolbar,
ion-header {
  --min-height: 60px;
  --ion-toolbar-background: transparent;
}

.agree_privte .v-label {
  font-size: 18px;
  margin-top: 3px;
}
</style>
