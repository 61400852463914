import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMq from "vue-mq";

import Ionic from "@ionic/vue";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";

// multi language
import "./ml";

// browser detect
import browserDetect from "vue-browser-detect-plugin";

Vue.use(VueAxios, axios);

Vue.use(browserDetect);

Vue.config.productionTip = false;
Vue.use(Ionic);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 960,
    md: 1920,
    lg: Infinity
  },
  defaultBreakpoint: "lg" // customize this for SSR
});

// 일부 안드로이드 폰 중에서 키보드가 보여질 때 줄어들었던 viewport가
// 키보드가 사라질 때 다시 늘어나지 않는 버그가 있어서 아래 코드 추가 - kjsim 200229
window.addEventListener("keyboardWillHide", () => {
  const app = document.querySelector("ion-app");
  window.requestAnimationFrame(() => {
    app.style.height = "100%";
    window.requestAnimationFrame(() => {
      app.style.height = "";
    });
  });
});

const vm = new Vue({
  vuetify,
  store,
  router,
  axios,
  render: h => h(App)
}).$mount("#app");

export { vm };
